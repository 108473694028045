import React, {useContext}from "react";
import UserContext from "../../UserContext";
import { Box, Typography, Button } from "@mui/material";
import AccordionTable from "../../Components/AccordionTable";
import DownloadIcon from "@mui/icons-material/Download";
import {BrandVendorPackMapping , VendorPack } from "../../vendorPack.js";


function Home() {

  const {user} = useContext(UserContext);
  const table = [
    {
      title: "FESTIVAL OPERATING HOURS",
      rows: [

        ["December 13 - 15", "Doors open to public" , "15:00 – 02:00*"],
        ["", "* Vendors must have their spaces open and manned during these hours on all festival days." , ""],
      ],
    },
    {
      title: "SUBMISSION DEADLINES",
      rows: [
        ["October 16 ", "Activation & Programming"],
        ["October 16 ", "Design Concept"],
        ["November 01", "Technical Submission"],
        ["November 01", "Permits & Licensing"],
        ["November 12", "Site Services"],
        ["November 21", "Site Access & Staff Accreditation"],
      ],
    },
    {
      title: "BUILD MILESTONES",
      rows: [
        ["December 12", "Installation complete ( no later than 07:00 )"],
        ["December 12", "Dubai Civil Defense (DCD) inspection"],
        ["December 13", "Final SOLE inspection"],
      ],
    },
    {
      title: "LOAD IN/BUILD",
      rows: [
        ["9-day set up", "Anchors, Sponsors, Large Custom (G+1)"],
		["8-day set up", "Anchors, Sponsors, Large Custom (G)"],
        ["7-day set up", "Large Custom, F&B Standard (G)"],
        ["4-day set up", "Medium"],
        ["3-day set up", "Small"],
        ["1-day set up", "F&B Small, F&B Kiosk, Spotlight"],
        ["1-day set up", "Sneaker Swap & Thrift, Trade Hall"],
      ],
    },
    {
      title: "LOAD OUT/TEARDOWN",
      rows: [
        ["December 16", "07:00 - 12:00: Product packing and debris clearance."],
        [
          "December 16",
          "12:00 - 17:00: Remove products, loose furniture, modular/simple fittings and fixtures. Clearance of storage containers, dressing rooms, and support offices.",
        ],
        [
          "December 17",
          "07:00 - 17:00: Removal of hard furniture, kitchen equipment, impediments, cladding, wiring, tech, A/V, storage units, dressing rooms, and office units.",
        ],
        [
          "December 18",
          "07:00 - 17:00: Removal of cladding, trussing, shading, rails, electrical, and major structural components. Clearance of waste.",
        ],
      ],
    },
  ];

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{margin: {xs:"1rem" , md: "2rem 8rem"}, width:{xs:"90%", md:"60%"} }}  >
        <Typography variant="h6">Sole DXB is back!</Typography>
        <Typography variant="body1">
          Welcome to the 2024 edition of the city’s most anticipated homegrown festival.{" "}
        </Typography>
        <Typography variant="body1">
          We’re glad to have you on board. This year accelerate your registration experience on a platform that does it all – from document submissions and accreditation, to booking site services.
        </Typography>
        <Box sx={{ mt: 5 }}>
          {table.map((tabledata) => (
            <AccordionTable data={tabledata} />
          ))}
        </Box>
        {user?.company && 
        <Box
        sx={{margin: {xs:"1rem 0" , md:"5rem 0"}}}
        style={{
          width: "100%",
          
          display: "flex",
          flexDirection: "row",
          gap: 20,
        }}
      >
        <a href={VendorPack[user?.type]} style={{textDecoration: "none"}} target="_blank" alt="Vendor Pack" >
        <Button
          variant="contained"
          style={{ color: "#FFF", backgroundColor: "#000" }}
          endIcon={<DownloadIcon />}
        > Vendor pack - {user?.type} 
        </Button>
        </a>
        {/* <Button
          variant="contained"
          style={{ color: "#FFF", backgroundColor: "#000" }}
          endIcon={<DownloadIcon />}
        >
          sustainability guide
        </Button>
        <Button
          variant="contained"
          style={{
            color: "#FFF",
            backgroundColor: "#000",
          }}
          endIcon={<DownloadIcon />}
        >
          layout
        </Button> */}
      </Box>
        }
        
      </Box>
     
    </Box>
  );
}

export default Home;
