export const VendorPack = {
        "Anchor":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_Anchor.pdf?alt=media&token=25f3b905-e73f-48e9-9889-9ed97fba53e9",
        "Large":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_Large.pdf?alt=media&token=541c2335-d6f6-4f1d-9c71-f0b5658f69fa",
        "Medium":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_Medium.pdf?alt=media&token=95775cf2-fe15-4dc3-84ba-9b33cb9a48e3",
        "Small":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_Small.pdf?alt=media&token=0080616a-f8f3-42ce-a334-13f5529f0c80",
        "Sponsor":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_Sponsor.pdf?alt=media&token=586429bc-0552-4fd8-9a85-3452787df151",
        "Spotlight":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_SpotlightKiosk.pdf?alt=media&token=4eb09347-a543-4218-a1e9-59279664e79b",
		"F&B Kiosk":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_F%26BKiosk.pdf?alt=media&token=bd6ee5e7-5e51-4507-aa20-337349a58dbd",
		"F&B Small":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_F%26BSmall.pdf?alt=media&token=dba9485b-13d8-4743-99c9-5f421724fe0d",
		"F&B":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_F%26BStandard.pdf?alt=media&token=243aef86-195d-495c-9d42-23b5632bd104",
        "F&B Large":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_F%26BLarge.pdf?alt=media&token=1578d2af-390b-436a-9dc3-3bf6d0d22d53",
        "F&B Bar":"https://firebasestorage.googleapis.com/v0/b/sole-dxb-2022.appspot.com/o/User%20Downloadable%2FVendorPacks%2FSOLEDXB_VendorPack_F%26BBar.pdf?alt=media&token=4ef58936-8158-4c47-91da-bfde8124f502",


}



export const BrandVendorPackMapping = {
Puma:"Anchor",
"New Balance":"Anchor",
"The Giving Movement":"Anchor",
"Jägermeister": "Sponsor_ASOC",
"Patrón": "SponsorTBC",
"Les Benjamins" :"Large Custom", 
Crocs :"Large Custom", 
"Crep Protect":"Large Custom",
"Hugo B oss" :"Large Custom", 
"Fred Perry" :"Large Custom", 
"Kappa":"Large Custom", 
"Sephora":"Large Custom", 
"Adidas Originals":"Large Custom", 
"2K":"Large Custom", 
"Freedom":"Large Custom", 
AAPE :"Medium", 
"D1 Milano" :"Medium", 
"1886" :"Medium", 
"5ive Pillars" :"Medium", 
"Shabab Intl" :"Medium", 
"Virgin Megastore" :"Medium", 
"The Gold Gods" :"Medium", 
"Le Labo" :"Medium", 
"No. 10":"Medium", 
"OTB" :"Medium", 
"Azeema Magazine" :"Medium", 
"On Running" :"Medium", 
"Precious Trust" :"Medium", 
"XL Extralight + RAL7000 Studio" :"Medium", 
"Blockhead" :"Medium", 
"Def Jam" :"Medium", 
"BLESSD" :"Medium", 
"Sole Graduate Showcase":"Medium", 
PPC:"Medium", 
Toms :"Small", 
Claudette :"Small", 
"Wild Arab West" :"Small", 
"Angel Syrup" :"Small", 
"Crkd Guru" :"Small", 
"Freedom of Space" :"Small", 
"Mitchell & Ness" :"Small", 
Dopamine:"Small",
Grade:"Small",
"25Hours Hotel":"Small",
"West to West Kitchen": "F&B",
"Rascals": "F&B",
"Neat Burger": "F&B",
"Cali Poke": "F&B",
"Pickl": "F&B",
"Shake Shack": "F&B",
"Burro Blanco": "F&B",
"Yifang Tea":"F&B",
"Artisan Ice":"F&B",
"Pinza":"F&B",
"O Donuts":"F&B",
"Fifth Flavor":"F&B",
"Project Chaiwalla":"F&B",
"Trill Icecream":"F&B",
"Meera Adnan":"Market",
"SN3":"Market",
"Plussmmmore":"Market",
"Zac Bendriouich":"Market",
"Thaely":"Market",
"Pacifism":"Market",
UNTY:"Market",
"Trigger Malfunction":"Market",
"Kardo":"Market",
"Sound.On":"Market",
"Finchitua":"Market",
"Face Yoga":"Market",
"Power Horse" :"Sponsor_PH", 
Cashew :"Associate Sponsor", 
Philips :"Medium Sponsor", 
};

export const brandList = ["Puma","New Balance","The Giving Movement","Les Benjamins","Crocs","Crep Protect","Hugo Boss","Fred Perry","Kappa","Sephora","Adidas Originals","2K","Freedom","AAPE","D1 Milano","1886","5ive Pillars","Shabab Intl","Virgin Megastore","The Gold Gods","Le Labo","No. 10","OTB","Azeema Magazine","On Running","Precious Trust","XL Extralight + RAL7000 Studio","Blockhead","Def Jam","BLESSD","Toms","Claudette","Wild Arab West","Angel Syrup","Crkd Guru","Freedom of Space","Mitchell & Ness","Power Horse","Cashew","Jägermeister","Patrón","Philips","West to West Kitchen","Rascals","Neat Burger","Cali Poke","Pickl","Shake Shack"]