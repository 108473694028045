export const Loadservices = [
    {
      title: "Crane 20 Tonne (10 hour)* includes operator & fuel",
      type:20,
      cost:{
        20:{"1 Day":2000.00, "3 Days":5700.00, "Weekly":12600.00, "Extra Hour":200.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
        {
          title: "Loadin",
          val: {
            "1 Day": [
              "04 Dec",
			  "05 Dec",
			  "06 Dec",
			  "07 Dec",
			  "08 Dec",
			  "09 Dec",
			  "10 Dec",
			  "11 Dec",
            ],
            "3 Days": [
              "04 - 06 Dec",
			  "05 - 07 Dec",
			  "06 - 08 Dec",
			  "07 - 09 Dec",
			  "08 - 10 Dec",
			  "09 - 11 Dec",
            ],
            Weekly: ["04 - 10 Dec",
				"05 - 11 Dec",],
            "Extra Hour": [
             "04 Dec",
			   "05 Dec",
			   "06 Dec",
			   "07 Dec",
			   "08 Dec",
			   "09 Dec",
			   "10 Dec",
			   "11 Dec",
			   "16 Dec",
				 "17 Dec",
				 "18 Dec",
            ],
          },
        },
        {
          title: "Loadout",
          val: {
            "1 Day": [
              "16 Dec",
			  "17 Dec",
			  "18 Dec",
            ],
            "3 Days": [
             "16 - 18 Dec",
            ],
          },
        },
      ],
    },
    {
      title: "Crane 50 Tonne* includes operator & fuel",
      type:50,
      cost:{
        50:{"1 Day":3000.00, "3 Days":8550.00, "Weekly":18900.00, "Extra Hour":300.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
     dates: [
		 {
		   title: "Loadin",
		   val: {
			 "1 Day": [
			   "04 Dec",
			   "05 Dec",
			   "06 Dec",
			   "07 Dec",
			   "08 Dec",
			   "09 Dec",
			   "10 Dec",
			   "11 Dec",
			 ],
			 "3 Days": [
			   "04 - 06 Dec",
			   "05 - 07 Dec",
			   "06 - 08 Dec",
			   "07 - 09 Dec",
			   "08 - 10 Dec",
			   "09 - 11 Dec",
			 ],
			 Weekly: ["04 - 10 Dec",
				 "05 - 11 Dec",],
			 "Extra Hour": [
			  "04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
				"16 Dec",
				  "17 Dec",
				  "18 Dec",
			 ],
		   },
		 },
		 {
		   title: "Loadout",
		   val: {
			 "1 Day": [
			   "16 Dec",
			   "17 Dec",
			   "18 Dec",
			 ],
			 "3 Days": [
			  "16 - 18 Dec",
			 ],
		   },
		 },
	   ],
    },
	{
	  title: "Crane 100 Tonne* includes operator & fuel",
	  type:100,
	  cost:{
		100:{"1 Day":5000.00, "3 Days":14250.00, "Weekly":31500.00, "Extra Hour":500.00}},
	  dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
	 dates: [
		 {
		   title: "Loadin",
		   val: {
			 "1 Day": [
			   "04 Dec",
			   "05 Dec",
			   "06 Dec",
			   "07 Dec",
			   "08 Dec",
			   "09 Dec",
			   "10 Dec",
			   "11 Dec",
			 ],
			 "3 Days": [
			   "04 - 06 Dec",
			   "05 - 07 Dec",
			   "06 - 08 Dec",
			   "07 - 09 Dec",
			   "08 - 10 Dec",
			   "09 - 11 Dec",
			 ],
			 Weekly: ["04 - 10 Dec",
				 "05 - 11 Dec",],
			 "Extra Hour": [
			  "04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
				"16 Dec",
				  "17 Dec",
				  "18 Dec",
			 ],
		   },
		 },
		 {
		   title: "Loadout",
		   val: {
			 "1 Day": [
			   "16 Dec",
			   "17 Dec",
			   "18 Dec",
			 ],
			 "3 Days": [
			  "16 - 18 Dec",
			 ],
		   },
		 },
	   ],
	},
    {
      title: "Forklift 5 Tonne (10 hour) *includes operator & fuel",
      deliveryCharge:"1,200",
      type:5,
      cost:{
        5:{"1 Day":1200.00, "3 Days":3420, "Weekly":7560.00, "Extra Hour":120.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
		  {
			title: "Loadin",
			val: {
			  "1 Day": [
				"04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
			  ],
			  "3 Days": [
				"04 - 06 Dec",
				"05 - 07 Dec",
				"06 - 08 Dec",
				"07 - 09 Dec",
				"08 - 10 Dec",
				"09 - 11 Dec",
			  ],
			  Weekly: ["04 - 10 Dec",
				  "05 - 11 Dec",],
			  "Extra Hour": [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
			},
		  },
		  {
			title: "Loadout",
			val: {
			  "1 Day": [
				"16 Dec",
				"17 Dec",
				"18 Dec",
			  ],
			  "3 Days": [
			   "16 - 18 Dec",
			  ],
			},
		  },
		],
    },
    {
      title: "Forklift 7 Tonne (10 hour) *includes operator & fuel",
      deliveryCharge:"1,200",
      type : 7,
      cost:{
        7:{"1 Day":1500.00, "3 Days":4275.00, "Weekly":9450.00, "Extra Hour":150.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
    dates: [
		{
		  title: "Loadin",
		  val: {
			"1 Day": [
			  "04 Dec",
			  "05 Dec",
			  "06 Dec",
			  "07 Dec",
			  "08 Dec",
			  "09 Dec",
			  "10 Dec",
			  "11 Dec",
			],
			"3 Days": [
			  "04 - 06 Dec",
			  "05 - 07 Dec",
			  "06 - 08 Dec",
			  "07 - 09 Dec",
			  "08 - 10 Dec",
			  "09 - 11 Dec",
			],
			Weekly: ["04 - 10 Dec",
				"05 - 11 Dec",],
			"Extra Hour": [
			 "04 Dec",
			   "05 Dec",
			   "06 Dec",
			   "07 Dec",
			   "08 Dec",
			   "09 Dec",
			   "10 Dec",
			   "11 Dec",
			   "16 Dec",
				 "17 Dec",
				 "18 Dec",
			],
		  },
		},
		{
		  title: "Loadout",
		  val: {
			"1 Day": [
			  "16 Dec",
			  "17 Dec",
			  "18 Dec",
			],
			"3 Days": [
			 "16 - 18 Dec",
			],
		  },
		},
	  ],
    },
	{
      title: "Telehandler / Boom loader 14m (10 hour*) includes operator & fuel",
      deliveryCharge:"1,200",
      type: 14,
      cost:{
        14:{"1 Day":1350.00, "3 Days":3850.00, "Weekly":8500.00, "Extra Hour":135.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
     dates: [
		 {
		   title: "Loadin",
		   val: {
			 "1 Day": [
			   "04 Dec",
			   "05 Dec",
			   "06 Dec",
			   "07 Dec",
			   "08 Dec",
			   "09 Dec",
			   "10 Dec",
			   "11 Dec",
			 ],
			 "3 Days": [
			   "04 - 06 Dec",
			   "05 - 07 Dec",
			   "06 - 08 Dec",
			   "07 - 09 Dec",
			   "08 - 10 Dec",
			   "09 - 11 Dec",
			 ],
			 Weekly: ["04 - 10 Dec",
				 "05 - 11 Dec",],
			 "Extra Hour": [
			  "04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
				"16 Dec",
				  "17 Dec",
				  "18 Dec",
			 ],
		   },
		 },
		 {
		   title: "Loadout",
		   val: {
			 "1 Day": [
			   "16 Dec",
			   "17 Dec",
			   "18 Dec",
			 ],
			 "3 Days": [
			  "16 - 18 Dec",
			 ],
		   },
		 },
	   ],
    },
    {
      title: "Telehandler / Boom loader 17m (10 hour*) includes operator & fuel ",
      deliveryCharge:"1,200",
      type: 17,
      cost:{
        17:{"1 Day":1800.00, "3 Days":5130.00, "Weekly":11340.00, "Extra Hour":180.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
		  {
			title: "Loadin",
			val: {
			  "1 Day": [
				"04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
			  ],
			  "3 Days": [
				"04 - 06 Dec",
				"05 - 07 Dec",
				"06 - 08 Dec",
				"07 - 09 Dec",
				"08 - 10 Dec",
				"09 - 11 Dec",
			  ],
			  Weekly: ["04 - 10 Dec",
				  "05 - 11 Dec",],
			  "Extra Hour": [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
			},
		  },
		  {
			title: "Loadout",
			val: {
			  "1 Day": [
				"16 Dec",
				"17 Dec",
				"18 Dec",
			  ],
			  "3 Days": [
			   "16 - 18 Dec",
			  ],
			},
		  },
		],
    },
    {
      title: "Cherry Picker 15m (10 hours)",
      deliveryCharge:"1,200",
      type: 15,
      cost:{
        15:{"1 Day":1250, "3 Days":3560.00, "Weekly":7875}},
      dList: ["1 Day", "3 Days", "Weekly"],
      dates: [
		  {
			title: "Loadin",
			val: {
			  "1 Day": [
				"04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
			  ],
			  "3 Days": [
				"04 - 06 Dec",
				"05 - 07 Dec",
				"06 - 08 Dec",
				"07 - 09 Dec",
				"08 - 10 Dec",
				"09 - 11 Dec",
			  ],
			  Weekly: ["04 - 10 Dec",
				  "05 - 11 Dec",],
			  "Extra Hour": [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
			},
		  },
		  {
			title: "Loadout",
			val: {
			  "1 Day": [
				"16 Dec",
				"17 Dec",
				"18 Dec",
			  ],
			  "3 Days": [
			   "16 - 18 Dec",
			  ],
			},
		  },
		],
    },
	{
      title: "Scissor Lift 12m (10 hours)",
      deliveryCharge:"1,200",
      type: 12,
      cost:{
      12:{"1 Day":1250.00, "3 Days":3560.00, "Weekly":7875.00}},
      dList: ["1 Day", "3 Days", "Weekly"],
      dates: [
		  {
			title: "Loadin",
			val: {
			  "1 Day": [
				"04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
			  ],
			  "3 Days": [
				"04 - 06 Dec",
				"05 - 07 Dec",
				"06 - 08 Dec",
				"07 - 09 Dec",
				"08 - 10 Dec",
				"09 - 11 Dec",
			  ],
			  Weekly: ["04 - 10 Dec",
				  "05 - 11 Dec",],
			  "Extra Hour": [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
			},
		  },
		  {
			title: "Loadout",
			val: {
			  "1 Day": [
				"16 Dec",
				"17 Dec",
				"18 Dec",
			  ],
			  "3 Days": [
			   "16 - 18 Dec",
			  ],
			},
		  },
		],
    },
	{
      title: "Cherry Picker/Scissor Lift | Operator (10 hours)",
      type:1,
      cost:{1:{"1 Day":1250.00, "3 Days":3560.00, "Weekly":7875.00, "Extra Hour":125.00}},
      dList: ["1 Day", "3 Days", "Weekly", "Extra Hour"],
      dates: [
		  {
			title: "Loadin",
			val: {
			  "1 Day": [
				"04 Dec",
				"05 Dec",
				"06 Dec",
				"07 Dec",
				"08 Dec",
				"09 Dec",
				"10 Dec",
				"11 Dec",
			  ],
			  "3 Days": [
				"04 - 06 Dec",
				"05 - 07 Dec",
				"06 - 08 Dec",
				"07 - 09 Dec",
				"08 - 10 Dec",
				"09 - 11 Dec",
			  ],
			  Weekly: ["04 - 10 Dec",
				  "05 - 11 Dec",],
			  "Extra Hour": [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
			},
		  },
		  {
			title: "Loadout",
			val: {
			  "1 Day": [
				"16 Dec",
				"17 Dec",
				"18 Dec",
			  ],
			  "3 Days": [
			   "16 - 18 Dec",
			  ],
			},
		  },
		],
    },
  ];

  export const Securityservices = [
    {
      title: "Male Security Guard (12 Hours) | Day Shift | 1 Day*",
      type: 1,
      cost:1100.00,
      dates: [
       "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
      ],
    },
    {
      title: "Male Security Guard (12 Hours) | Day Shift | 3 Days*",
      type: 3,
      cost:3135.00,
      dates: ["12 - 14 Dec",
"13 - 15 Dec",],
    },
    {
      title: "Male Security Guard (12 Hours) | Day Shift | 1 Week*",
      type: 7,
      cost:6930.00,
      dates: [
        "12 - 18 Dec",
      ],
    },
    {
        title: "Male Security Guard (12 Hours) | Night Shift | 1 Day*",
        type: 1,
        cost:1200.00,
        dates: [
           "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
          ],
      },
      {
        title: "Male Security Guard (12 Hours) | Night Shift | 3 Days*",
         type: 1,
        cost:3420.00,
        dates:["12 - 14 Dec",
"13 - 15 Dec",],
      },
      {
        title: "Male Security Guard (12 Hours) | Day Shift | 1 Week*",
        type: 7,
        cost:7560.00,
        dates: [
          "12 - 18 Dec",
          ],
      },
      {
        title: "Female Security Guard (12 Hours) | Day Shift | 1 Day*",
        type: 1,
        cost:1200.00,
        dates: [
           "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
          ],
      },
      {
        title: "Female Security Guard (12 Hours) | Day Shift | 3 Days*",
        type: 3,
        cost:3420.00,
        dates: ["12 - 14 Dec",
"13 - 15 Dec",],
      },
      {
        title: "Male Security Guard (12 Hours) | Day Shift | 1 Week*",
        type: 7,
        cost:7560.00,
        dates: [
          "12 - 18 Dec",
          ],
      },
      {
        title: "Male Bouncer (12 Hours) | 1 Day*",
        type: 1,
        cost:1500.00,
        dates: [
           "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",],
      },
      {
        title: "Male Bouncer (12 Hours) | 3 Days*",
        type: 3,
        cost:4275.00,
        dates: ["12 - 14 Dec",
"13 - 15 Dec",],
      },
      {
        title: "Male Bouncer (12 Hours) | 1 Week*",
        type: 7,
        cost:9450.00,
        dates: ["12 - 18 Dec",],
      },
      {
        title: "Supervisor Day Rate (12 Hours) (1 for every 6 guards) | 1 Day*",
        type: 1,
        cost:1500.00,
        dates: [
           "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
          ],
      },
      {
        title: "Supervisor Day Rate (12 Hours) (1 for every 6 guards) | 3 Day*",
        type:  3,
        cost:4275.00,
        dates: ["12 - 14 Dec",
"13 - 15 Dec",]
      },
      {
        title: "Supervisor Day Rate (12 Hours) (1 for every 6 guards) | 1 Week*",
        type: 7,
        cost:9450.00,
        dates: ["12 - 18 Dec",],
      },
      {
        title: "CCTV Installation - 8 camera set up | 3 Days*",
        type: 3,
        cost:10000.00,
        dates: ["13 Dec - 15 Dec"]
      },

  ];

  export const Cleaningservices = [
    {
      title: "Male Cleaning Staff (12 Hours) | 1 Day*",
      type: 1,
      cost:800.00,
      dates: [
       "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
      ],
    },
    {
      title: "Male Cleaning Staff (12 Hours) | 3 Days*",
      type: 3,
      cost:2160.00,
      dates:["13 Dec - 15 Dec"],
    },{
    title: "Female Cleaning Staff (12 Hours) | 1 Day*",
     type: 1,
    cost:800.00,
    dates: [
     "12 Dec",
"13 Dec",
"14 Dec",
"15 Dec",
"16 Dec",
    ],
  },
  {
    title: "Female Cleaning Staff (12 Hours) | 3 Days*",
    type: 3,
    cost:2160.00,
    dates: ["13 Dec - 15 Dec"],
   }
];

  export const Fireservices = [
    {
      title: "Smoke Alarm System | 3 Days",
      addText: "Set of 4, battery operated",
      types: 1,
      cost: 750.00,
      dates: [
       "13 Dec - 15 Dec"
      ]
    },
    {
      title: "Small Fire Extinguisher (set of 2) | 3 Days",
      type:3,
      cost: 340.00,
      dates: ["13 Dec - 15 Dec"]
    },
    {
      title: "Big Fire Extinguisher | 3 Days",
      type:3,
      cost:650.00,
      dates: ["13 Dec - 15 Dec"]
    }
];

export const Storageservices = [
    {
      title: "Container with Light & Padlock [6x2.5m] | 3 Days",
     type:3,
      cost:4500.00,
      dates: ["13 Dec - 15 Dec"]
    },
    {
    title: "Container with AC, Light & Padlock [6x2.5m] | 3 Days",
    type: 3,
    cost:7500.00,
    dates: ["13 Dec - 15 Dec"]
  }
];

export const Coldroomservices = [
	{
	  title: "Cold Rooms - 2.4 M x 2.4 M",
	 type:3,
	  cost:4500.00,
	  deliveryCharge:1800.00,
	  dates: ["13 Dec - 15 Dec"]
	},
		{
		title: "Cold Rooms - 3.6 M x 2.4 M",
		type: 3,
		cost:5000.00,
		  deliveryCharge:1800.00,
		dates: ["13 Dec - 15 Dec"]
	  },
		  {
		  title: "Cold Rooms - 4.8 M x 2.4 M",
		  type: 3,
		  cost:5500.00,
			deliveryCharge:1800.00,
		  dates: ["13 Dec - 15 Dec"]
		},
			{
			title: "Cold Rooms - 6 M x 2.4 M",
			type: 3,
			cost:6000.00,
			  deliveryCharge:1800.00,
			dates: ["13 Dec - 15 Dec"]
		  },
			  {
			  title: "Transport & Installation - per cold rooms",
				type: 3,
				cost:1800.00,
				  deliveryCharge:1800.00,
				dates: ["13 Dec - 15 Dec"]
			  }
];

export const Roomservices = [
    {
      title: "32-foot twin room with attached washroom | 3 Days",
      type: 3,
      cost:10500.00,
      dates: ["13 Dec - 15 Dec"]
    },
    {
      title: "32-foot twin room without washroom | 3 Days",
      type: 1,
      cost:9000.00,
      dates: ["13 Dec - 15 Dec"]
    },
    {
      title: "20-foot single/twin room without washroom | 3 Days",
      type: 1,
      cost:7500.00,
      dates: ["13 Dec - 15 Dec"]
    },
    
  {
    title: "3-seater Sofa - White | 3 Days",
    type:  3,
    cost:1250.00,
    dates: ["13 Dec - 15 Dec"]
  },
  {
    title: "1-seater Sofa - White | 3 Days",
    type:  3,
    cost:500.00,
    dates: ["13 Dec - 15 Dec"]
  },
  {
    title: "Coffee Table | 3 days",
    types: 3,
    cost:400.00,
    dates:["13 Dec - 15 Dec"]
  },
  {
    title: "Banquet Chairs with cover  | 3 Days",
    type: 3,
    cost:125.00,
	dates:["13 Dec - 15 Dec"]
  },
  {
    title: "Banquet Tables with cover | 3 Days",
    type: 3,
    cost:325.00,
	dates:["13 Dec - 15 Dec"]
  },
  {
    title: "Hanger Racks | 3 Days",
    type:  3,
    cost:250.00,
	dates:["13 Dec - 15 Dec"]
  },
	{
	  title: "Dustbin | 3 Days",
	  type: 3,
	  cost:75.00,
	  dates:["13 Dec - 15 Dec"]
	},
	  {
		title: "Furniture delivery & collection - per trip",
		type: 3,
		cost:1200.00,
		dates:["13 Dec - 15 Dec"]
	  },
];


export const Powerservices = [
    {
      title: "32 Amp 1-phase connection | 3 Days",
      types: 3,
      cost:3000.00,
      dates:["13 Dec - 15 Dec"]
    },
    {
        title: "32 Amp 3-phase connection | 3 Days",
        type: 3,
        cost:5000.00,
        dates:["13 Dec - 15 Dec"]
      },
		{
			title: "VT1 Tower (includes fuel) | 1 Day",
			type:  3,
			cost:2000.00,
			dates: [
			   "04 Dec",
				 "05 Dec",
				 "06 Dec",
				 "07 Dec",
				 "08 Dec",
				 "09 Dec",
				 "10 Dec",
				 "11 Dec",
				 "12 Dec",
				 "16 Dec",
				   "17 Dec",
				   "18 Dec",
			  ],
		  },
			{
				title: "VT1 Tower (includes fuel) | 3 Day",
				type:  3,
				cost:2500.00,
				dates: [ "04 - 06 Dec",
					  "05 - 07 Dec",
					  "06 - 08 Dec",
					  "07 - 09 Dec",
					  "08 - 10 Dec",
					  "09 - 11 Dec",
					  "10 - 12 Dec",]
			  },
        {
            title: "VT1 Tower (includes fuel) | 1 Week",
            type:  7,
            cost:4500.00,
            dates: ["04 - 10 Dec",
				 "05 - 11 Dec","06 - 12 Dec",]
          },
];

export const Otherservices = [
    {
      title: "Queue Manager (per piece) | 1 Day",
      type:1,
      cost:150.00,
      dates: [
        "08-Dec-23","09-Dec-23","10-Dec-23"
      ]
    },
    {
      title: "Queue Manager (per piece)| 3 Days",
      type:  3 ,
      cost:340.00,
      dates:["13 Dec - 15 Dec"]
    },
    {
        title: "Ropes & Poles (per piece)| 1 Day",
        type: 1,
        cost:150.00,
        dates:[
          "13 Dec - 15 Dec",
        ]
      },
    {
        title: "Ropes & Poles (per piece)| 3 Days",
        types:  3,
        cost:340.00,
        dates: ["08-Dec-23","09-Dec-23","10-Dec-23"]
      },
];
